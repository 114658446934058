import { HStack, useDisclosure } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { PersonDto, PersonReferenceDto, PersonStatusDto } from '../../../../api';
import personApi from '../../../../data-access/person-api';
import ValueAsyncSelectControl, {
  ValueAsyncSelectControlProps,
} from '../../../../ui/form/select-control/value-async-select-control';
import usePermission from '../../../permission/use-permission';
import PersonDrawer from '../../../person/person-drawer/person-drawer';

export interface PersonSelectControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
    ValueAsyncSelectControlProps<PersonReferenceDto, TFieldValues, TName>,
    'loadOptions' | 'renderLabel' | 'optionIdentifier' | 'defaultOptions'
  > {
  filter?: string[];
  enableQuickPerson?: boolean;
  excludeFromResults?: PersonDto | PersonReferenceDto;
  flipName?: boolean;
}

const PersonSelectControl = React.forwardRef(
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    {
      filter = [`status,eq,${PersonStatusDto.ACTIVE}`],
      enableQuickPerson = false,
      flipName = false,
      excludeFromResults,
      control,
      name,
      isRequired,
      ...props
    }: PersonSelectControlProps<TFieldValues, TName>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { hasPermission } = usePermission();
    const { t } = useTranslation('person');
    const { field } = useController({ name, control });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const canCreatePersonFast = hasPermission('PERSON:CAN_CREATE');

    const handlePersonDrawerSave = (person: Omit<PersonDto, 'id'> & { id: string }) => {
      const personReference: PersonReferenceDto = {
        id: person.id,
        firstName: person.firstName,
        surname: person.surname,
        personKey: person.personKey,
        emailAddresses: person.emailAddresses?.map((email) => email.email),
      };

      field.onChange(personReference);
    };

    const renderLabel = (person: PersonReferenceDto) => {
      if (flipName) {
        return `${person.surname}, ${person.firstName} ${person.personKey}`;
      }
      return `${person.firstName} ${person.surname} ${person.personKey}`;
    };

    return (
      <>
        {canCreatePersonFast && enableQuickPerson && (
          <PersonDrawer isOpen={isOpen} onClose={onClose} onSave={handlePersonDrawerSave} />
        )}
        <ValueAsyncSelectControl<PersonReferenceDto, TFieldValues, TName>
          {...props}
          isRequired={isRequired && !isOpen}
          name={name}
          control={control}
          loadOptions={async (q: string, size: number) => {
            const { content } = await personApi.searchPersonReferences({
              pageable: { size },
              filter,
              q,
            });

            return content
              .filter((person) => excludeFromResults == null || person.id !== excludeFromResults.id)
              .map(({ firstName, id, personKey, surname }) => {
                invariant(id != null, 'Person ID missing');

                return { id, personKey, firstName, surname };
              });
          }}
          optionIdentifier={(person) => person.id}
          defaultOptions={false}
          renderLabel={renderLabel}
          enableCreate={canCreatePersonFast && enableQuickPerson}
          onCreateOption={onOpen}
          formatCreateLabel={() => (
            <HStack gap="0.5rem">
              <FontAwesomeIcon icon={faPlus} />
              <Trans t={t} i18nKey="quickPerson" />
            </HStack>
          )}
          ref={ref}
        />
      </>
    );
  },
);

export default PersonSelectControl;
